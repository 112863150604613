import React, { useState, useEffect } from 'react';
import '../index.css';
import { Badge } from 'antd';

const DiscountTimer = () => {
  let lang = localStorage.getItem('lang')
  let difference = +new Date('2024-06-28') - +new Date();
    const calculateTimeLeft = () => {
      let timeLeft = {};
      difference = +new Date('2024-06-28') - +new Date();
      if (difference > 0) {
        timeLeft = {
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60)
        };
      }
  
      return timeLeft;
    };
  
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  
    useEffect(() => {
      const timer = setTimeout(() => {
        setTimeLeft(calculateTimeLeft());
      }, 1000);
  
      return () => clearTimeout(timer);
    });
  
    const formatTime = (value) => {
      return String(value).padStart(2, '0');
    };
    if(difference > 1){
    return (
      
      <Badge.Ribbon text={lang === 'AZ' ? '-30%' : '-30%'} color="red" style={{transform: 'translateY(-30px)'}}>
        <div className="discount-timer">
            <div className="discount-block">
                <span className="discount-price">980 azn</span>
                <span className="main-price">1400 azn</span>
            </div>
            <div className="discount-timer-block">
                <span>{formatTime(timeLeft.days)}:</span>
                <span>{formatTime(timeLeft.hours)}:</span>
                <span>{formatTime(timeLeft.minutes)}:</span>
                <span>{formatTime(timeLeft.seconds)}</span>
            </div>
        </div>
      </Badge.Ribbon>
      )
    }else{
      return <div className="discount-timer">
                  <h1>1400 azn</h1>
            </div>
    }
  }
export default DiscountTimer;