import React from "react";
import styles from "./Portfolio.module.css"

const Card = ({img, device_img, mac, logo, title, subtitle}) => {
    return(
        <div className={styles.portfolio_card} style={{
            backgroundImage: `url("${img}")`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
        }}>
            <img src={device_img} style={{height: mac ? `auto` : '75%', width: mac ? '95%' : 'auto'}}/>
            <div className={styles.card_hover}>
                <img className={styles.brand_logo} src={logo}/>
                <div className={styles.card_info}>
                    <h3>{title}</h3>
                    <p>{subtitle}</p>
                </div>
            </div>
        </div>
    )
}

export default Card