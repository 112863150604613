import { gsap } from "gsap";
import React, { useEffect } from "react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import styles from "./faq.module.css"

gsap.registerPlugin(ScrollTrigger);
const Faq = () => {
    useEffect(() => {
        const textElements = document.querySelectorAll('.text');
    
        textElements.forEach((text) => {
          gsap.to(text, {
            backgroundSize: '100%',
            ease: 'none',
            scrollTrigger: {
              trigger: text,
              start: 'center 80%',
              end: 'center 20%',
              scrub: true,
            },
          });
        });
      }, []);
    const lang = localStorage.getItem('lang')
    if(lang === 'RU'){
      return (
        <div className={styles.container}>
            <h1 className={styles.text}>Ваша команда специализируется на каких платформах для разработки мобильных приложений?<span>Мы разрабатываем приложения для iOS и Android.</span></h1>
            <h1 className={styles.text}>Каковы сроки разработки обычного веб-сайта?<span>Обычно это занимает от нескольких недель до нескольких месяцев.</span></h1>
            <h1 className={styles.text}>Какие технологии вы используете для создания веб-сайтов?<span>HTML, CSS, JavaScript, React, Angular, Python, NodeJS</span></h1>
            <h1 className={styles.text}>Каков процесс разработки мобильного приложения от идеи до выпуска на рынок?<span style={{fontSize: '.9em'}}>Анализ идеи, затем проектируем интерфейс, разрабатываем приложение, тестируем и сдаём.</span></h1>
            <h1 className={styles.text}>Предоставляете ли вы поддержку и обслуживание после завершения проекта?<span style={{fontSize: '.9em'}}>Да, включая исправление ошибок, обновление функционала и техническую поддержку.</span></h1>
        </div>
      );
    }else{
      return (
        <div className={styles.container}>
          <h1 className={styles.text}>Sizin komanda mobil tətbiqlərin hazırlanması üçün hansı platformalara üstünlük verir?<span>İOS və Android üçün tətbiqlər hazırlayırıq.</span></h1>
          <h1 className={styles.text}>Adi veb saytın hazırlanma müddəti neçədir?<span>Bir neçə həftədən bir neçə ayadək çəkir.</span></h1>
          <h1 className={styles.text}>Veb saytların hazırlanmasında hansı texnologiyalardan istifadə edirsiniz?<span>HTML, CSS, JavaScript, React, Angular, Python, NodeJS texnologiyalarından istifadə edirik.</span></h1>
          <h1 className={styles.text}>Mobil tətbiqin fikirdən bazar çıxarılmış versiyasının hazırlanma prosesi nədir?<span style={{fontSize: '.9em'}}>Təhlil, interfeys, tətbiqin hazırlanması, sınaq, və publikasiya</span></h1>
          <h1 className={styles.text}>Proyekt başa çatdıqdan sonra dəstək və təmir göstərirsinizmi?<span style={{fontSize: '.9em'}}>Bəli, səhv düzəltmə, funksionallığın yenilənməsi və texniki dəstək daxil olmaqla.</span></h1>
        </div>
      );
    }
      
}

export default Faq;