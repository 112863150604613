import { Button, Form, message, Input, Modal, notification, Space, ConfigProvider } from "antd";
import { useEffect, useState } from "react";
import isValidPassword from "../utils/isValidPassword";
import isValidEmail from "../utils/isValidEmail";
import isValidPhoneNumber from "../utils/isValidPhoneNumber";
import axios from "axios";
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import "../components/ModalRegister.css"

export default function ModalRegister({open, setOpen}){
    const lang = localStorage.getItem('lang')
    const [messageApi, contextHolder] = message.useMessage();
    const [userPhoneNumber, setUserPhoneNumber] = useState()
    const [orderId, setOrderId] = useState('')
    const [sessionId, setSessionId] = useState('')
    const [userPassword, setUserPassword] = useState()
    const [userEmail, setUserEmail] = useState()
    const [loading, setLoading] = useState(false);
    const [api, contextNotificationHolder] = notification.useNotification();
    const [form] = Form.useForm();

    useEffect(() => {
        const storedDataOrder = localStorage.getItem('order_id');
        const storedDataSession = localStorage.getItem('session_id');
        if (storedDataOrder) {
          setOrderId(storedDataOrder);
        }else if(storedDataSession){
            setSessionId(storedDataSession)
        }
      }, []);

  const openNotification = (msg) => {
    if(msg === 'err'){
        api.info({
            message: lang === 'RU' ? `Произошла ошибка, повторите ещё раз` : 'Səhv baş verdi, xahiş edirəm bir daha cəhd edin.',
            placement: 'top',
          });
        return
    }
    api.info({
        message: lang === 'RU' ? `В течение 5 секунд вы будете перенаправленны на подтверждение покупки приложения` : '5 saniyə ərzində tətbiqin alınması təsdiqlənməsi üçün yönləndiriləcəksiniz.',
        placement: 'top',
      });
      
  };
    const success = () => {
        messageApi.open({
            type: 'success',
            content: 'Ваша заявка была отправлена, мы с вами свяжемся в скором времени!',
        });
    };
    const error = () => {
        messageApi.open({
            type: 'error',
            content: 'Произошла ошибка, попробуйте отправить заявку позже',
        });
    };
    const handleOk = () => {
        setTimeout(() => {
          setOpen(false);
        }, 3000);
      };
    const handleCancel = () => {    
        setOpen(false);
    };
    const redirectToPay = async (data) => {
        try{
            const response = await axios.post('https://antinovba.az:8080/api/payment/', data)
            localStorage.setItem('order_id', response.data.order_id)
            localStorage.setItem('session_id', response.data.session_id)
            setTimeout(() => {window.location.href = response.data.url;}, 100)
            //Записать order_id и session_id на local storage
        }catch(error){
            setLoading(false)
            if(error.request.status === 500) {
                const message =(
                    <Space>
                        <CloseCircleFilled style={{color: "red"}}/>
                        {lang === 'AZ' ? 'Səhv baş verdi' : 'Произошла ошибка'}
                    </Space>
                )
                notification.open({
                    message,
                    description: lang === 'RU' ? 'Ошибка сервера Kapital Bank, попробуйте позже' : 'Kapital Bank serverində xəta, zəhmət olmasa bir az sonra yenidən cəhd edin',
                    placement: "top",
                    onClick: () => {
                    console.log('Notification Clicked!');
                    },
                });
            }else return
        }
    }
    const onFinish = async (values) => {
        setLoading(true)
        try {
            const response = await axios.post('https://antinovba.az:8080/api/auth/users/', values);
            // openNotification()
            redirectToPay({'email': values.email})
            // registrationConfirmationNotification(values.email)
            form.resetFields()
            setOpen(false)
            console.log('Response:', response.data);
        } catch (error) {
            const message =(
                <Space>
                    <CloseCircleFilled style={{color: "red"}}/>
                    {lang === 'AZ' ? 'Səhv baş verdi' : 'Произошла ошибка'}
                </Space>
            )
            notification.open({
                message,
                description: lang === 'RU' ? 'Произошла ошибка, введённый email уже занят' : 'Səhv baş verdi, Daxil edilən email artıq istifadə olunur',
                placement: "top",
                onClick: () => {
                  console.log('Notification Clicked!');
                },
              });
            console.error('There was an error!', error);
            // openNotification('err')
        setLoading(false)
        }
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    const onChangeRequestNumber = (e) => {
        setUserPhoneNumber(e.target.value)
    }
    const onChangeRequestPassword = (e) => {
        setUserPassword(e.target.value)
    }
    const onChangeRequestMail = (e) => {
        setUserEmail(e.target.value)
    }
    const confirmNotification = () => {
        setOpen(false)
        setLoading(false)
    };

    const registrationConfirmationNotification = (userEmail) => {
        const key = `open${Date.now()}`;
        const btn = (
          <Space>
            <Button type="link" size="small" onClick={() => {
                setOpen(false)
                api.destroy(key)
            }} style={{width: '100px', fontSize: '18px', height: '50px', color: '#000', border: '1px solid #00000040'}}>
                {lang ==='AZ' ? 'Bağla' : 'Закрыть'}
            </Button>
            <Button className="pricechecker-filled-button" type="primary" size="small" onClick={() => {
                redirectToPay({'email': userEmail})
                api.destroy(key)
            }} style={{width: '100px', fontSize: '18px', height: '50px'}}>
                {lang ==='AZ' ? 'Alış' : 'Купить'}
            </Button>
          </Space>
        );
        const message = (
            <Space style={{padding: '10px 0px'}}>
                <CheckCircleFilled style={{color: "#57C769", fontSize: '30px'}}/>
                <h2>{lang === 'AZ' ? 'Qeydiyyat uğurla başa çatdı' : 'Регистрация прошла успешна'}</h2>
            </Space>
        )
        const description = (
            <p className="register_confirm_subttitle">
                {lang === 'AZ' ? 'Qeydiyyat uğurla keçdi, hesabı aktivləşdirmək üçün ödəniş etmək mütləqdir' : 'Регистрация прошла успешно, для подтверждения аккаунта обязательно сделать оплату'}
            </p>
        )
        api.open({
          duration: 0,
          message,
          description,
          btn,
          key,
          onClose: confirmNotification,
          placement: 'top',
        });
      };

    //ПРОВЕРИТЬ URL если есть параметр status то сделать последний запрос
    return(
        <ConfigProvider theme={{
            components: {
                Notification: {
                    width: 600,
                }
            }
        }}>
            <Modal
                open={open}
                title={lang === 'AZ' ? 'Qeydiyyat' : "Регистрация"}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
                width={700}
            >
                {contextNotificationHolder}
                {contextHolder}
                <Form
                    name="basic"
                    labelCol={{
                    span: 8,
                    }}
                    wrapperCol={{
                    span: 16,  
                    }}
                    style={{
                        maxWidth: 500,
                        margin: '0 auto',
                        padding: '40px 0'
                    }}
                    initialValues={{
                    remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    form={form}
                >
                    <Form.Item
                    label={lang === 'AZ' ? 'Adınız' : "Ваше имя"}
                    name="first_name"
                    rules={[
                        {
                            required: true,
                            message: lang === 'AZ' ? 'Adınızı yazın' : 'Введите ваше имя',
                        }
                    ]}
                    >
                    <Input type="text" name="user_name"/>
                    </Form.Item>
                    <Form.Item
                        label={lang === 'AZ' ? 'Soy adınız' : "Ваша фамилия"}
                        name="last_name"
                        rules={[
                            {
                                required: true,
                                message: lang === 'AZ' ? 'Soy adınızı yazın' : 'Введите вашу фамилию',
                            }
                    ]}
                    >
                    <Input type="text" name="user_surname"/>
                    </Form.Item>
                    
                    <Form.Item
                    label={lang === 'AZ' ? 'Telefon nömrəniz' : 'Номер телефона'}
                    name="phone"
                    rules={[
                        {
                            required: true,
                            message: lang === 'AZ' ? 'Nömrənizi yazın' : 'Введите номер',
                        },
                        {
                            validator: !isValidPhoneNumber(userPhoneNumber),
                            message: lang === 'AZ' ? 'Format düzgün deyil' : 'Не правильный формат',
                        }
                    ]}
                    >
                    <Input type="number" onChange={onChangeRequestNumber} value={userPhoneNumber} name="user_phone"/>
                    </Form.Item>
                    <Form.Item
                    label={lang === 'AZ' ? 'Elektron poçtu' : 'Электронный адрес'}
                    name="email"
                    rules={[
                        {
                            required: true,
                            message: lang === 'AZ' ? 'E-poçtunuzu daxil edin' : 'Укажите e-mail',
                        },
                        {
                            validator: !isValidEmail(userEmail),
                            message: lang === 'AZ' ? 'Format düzgün deyil' : 'Не правильный формат'
                        }
                    ]}
                    >
                    <Input type="text" onChange={onChangeRequestMail} value={userEmail} name="user_email"/>
                    </Form.Item> 
                    <Form.Item
                    label={lang === 'AZ' ? 'Şifrə' : 'Пароль'}
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: lang === 'AZ' ? 'Şifrə daxil edin' : 'Введите пароль',
                        },
                        {
                            min: 8,
                            message: lang === 'AZ' ? 'Şifrə 8 simvoldan yuxarı olmalıdır' : 'Пароль должен состоять из 8 символов и больше'
                        }
                    ]}
                    >
                    <Input.Password onChange={onChangeRequestPassword} value={userPassword} name="user_password"/>
                    </Form.Item>
                    <Form.Item
                        name="confirm"
                        label={lang === 'AZ' ? 'Şifrəni Təsdiqləyin' : 'Повторите Пароль'}
                        dependencies={['password']}
                        hasFeedback
                        rules={[
                        {
                            required: true,
                            message: 'Please confirm your password!',
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error(lang === 'AZ' ? 'Qeyd olunan şifrə uyğun deyil' : 'Пароли не совпадают!'));
                            },
                        }),
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item
                    wrapperCol={{
                        offset: 8,
                        span: 16,
                    }}
                    >
                    <Button className="pricechecker-filled-button" loading={loading} htmlType="submit" >
                        {lang === 'AZ' ? 'Qeydiyyat' : 'Регистрация'}
                    </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </ConfigProvider>
    )
}