import { CopyrightCircleFilled, CopyrightCircleOutlined, FacebookFilled, InstagramFilled, LinkedinFilled, MessageFilled, MessageOutlined, PhoneFilled, PhoneOutlined, PushpinFilled, PushpinOutlined } from '@ant-design/icons';
import './Footer.css';
import logo from "../assets/logo.png";

export default function Footer(){
    return(
        <div className='footer'>
            <div className='footer-title'>
                <img className='app-logo' src={logo}/>
                <span>Price Checker</span>
            </div>
            <div className='footer-contacts'>
                <div className='footer-contacts-item'>
                    <MessageFilled className='footer-contacts-item-icon'/>
                    <span>info@azsayt.az</span>
                </div>
                <div className='footer-contacts-item'>
                    <PhoneFilled className='footer-contacts-item-icon'/>
                    <span>+994 55 206 94 78</span>
                </div>
                <div className='footer-contacts-item'>
                    <PushpinFilled className='footer-contacts-item-icon'/>
                    <span>Əhməd Rəcəbli 255</span>
                </div>
            </div>
            <div className='footer-links'>
                <div className='footer-socials'>
                    <div className='footer-socials-item'>
                        <FacebookFilled className='footer-social-icon'/>
                    </div>
                    <div className='footer-socials-item'>
                        <LinkedinFilled className='footer-social-icon'/>
                    </div>
                    <div className='footer-socials-item'>
                        <InstagramFilled className='footer-social-icon'/>
                    </div>
                </div>
                <span className='footer-copyright-block'><CopyrightCircleOutlined className='footer-copyright-ico'/> Price Checker All Rights Reserved</span>
            </div>
        </div>
    )
}