import { Badge, Button, Card, Image } from "antd"
import PriceChckerCard from "./PriceCheckerCard"
import images_ico from "../assets/headerAbstractDesign.svg"
import images_ico2 from "../assets/headerimg.png"
import images_ico3 from "../assets/headeimg2.png"
import './HomeHeader.css'
import pricechecker from "../assets/PriceChecker.exe"
import { saveAs } from 'file-saver';

import pc1 from "../assets/PC1.png"
import pc2 from "../assets/PC2.png"
import pc3 from "../assets/PC3.png"
import pc4 from "../assets/PC4.png"
import pdfdoc from "../assets/PriceChecker Documentation.pdf"
import { LinkOutlined } from "@ant-design/icons"
import DiscountTimer from "./DiscountTimer"

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';


const cards_parrameters = [
    {   
        width: 445,
        company: 'Bytelecom',
        product: 'Iphone 14 Pro',
        price: 'azn 1600.00',
    },
    {   
        width: 410,
        company: 'Ispace',
        product: 'Iphone 14 Pro',
        price: 'azn 1660.00',
    },
    {   
        width: 375,
        company: 'Kontakt Home',
        product: 'Iphone 14 Pro',
        price: 'azn 1730.00',
    }
]

export default function HomeHeader({openModal}){
    const handleDownload = () => {
        // Путь к вашему exe файлу
        // Выполняем скачивание
        saveAs(pricechecker, 'PriceChecker.exe');
      };
      
    let lang = localStorage.getItem('lang')
    return(
        <header className="home-header">
            <div className="home-header-title-container">
                    <h1 className="home-header-title">{lang === 'RU' ? 'Мониторинг и управление ценами в ' : 'İnternet mağazada qiymətlərin monitorinqi və '}<span style={{color: '#57C769'}}>{lang === 'RU' ? 'интернет-магазине ' : 'idarə edilməsi'}</span></h1>
                    <p className="home-header-subtitle">
                        { lang === 'RU' ? 'Представляем вашему вниманию инновационную программу для мониторинга и управления ценами в интернет-магазине! Наша программа создана специально для продавцов, которые хотят эффективно контролировать свои цены и опережать конкурентов.' : 'İnternet mağazada qiymətlərin monitorinqi və idarə edilməsi üçün innovativ proqramımızı təqdim edirik! Proqramımız, qiymətləri effektiv şəkildə idarə etmək və rəqibləri qabaqlamaq istəyən satıcılar üçün xüsusi olaraq hazırlanmışdır.'}
                    </p>
                    
                    <div className="home-header-discount-block">
                        <DiscountTimer/>
                    </div>
                <div>
                    <div className="home-header-start-block">
                        <Button onClick={() => {openModal(true)}} className="pricechecker-filled-button home-header-title-signup-btn" style={{height: 63, width: '210px', fontSize: '20px'}}>
                            {lang === 'AZ' ? 'Başlamaq' : 'Начать'}
                        </Button>
                        <Button onClick={handleDownload} className="pricechecker-filled-button home-header-title-download-btn" style={{width: '210px', height: 63, fontSize: '20px'}}>
                            {lang === 'RU' ? 'Скачать' : 'Yükləmək'}
                        </Button>
                    </div>
                    <div className="home-header-pdfdoclink">
                        <a href={pdfdoc} target="_blank">{lang === 'AZ' ? 'Price Checker Dokumentasiya' : 'Документация Price Checker'}<LinkOutlined className="link_ico"/></a>
                    </div>
                </div>
            </div>
            {/* <div className="home-header-title-images-container">
                <img className="home-header-title-images-container-ico2" src={images_ico2}/>
                <img className="home-header-title-images-container-ico3" src={images_ico3}/>
                <div className="home-header-title-images-container-whitebg"></div>
                <img className="home-header-title-images-container-ico" src={images_ico}/>
                <p className="home-header-title-images-container-title">{lang === 'AZ' ? 'Filter olmuş qiymətlər' : 'Отфильтрованный список цен'}</p>
                <div className="home-header-title-images-container-card">
                {
                    cards_parrameters.map((card, index) => {
                        return(
                            <PriceChckerCard className="home-header-title-images-container-card-item" index={index} parrams={card}/>
                        )
                    })
                }
                </div>
                <div className="home-header-title-download-container">
                    <p className="home-header-title-download-container-title">{lang === 'RU' ? 'Скачать Price Checker' : 'Price Checker Yükləyin'}</p>
                    <Button onClick={handleDownload} className="pricechecker-filled-button home-header-title-download-btn" style={{width: '100%', height: 50}}>
                    {lang === 'RU' ? 'Скачать' : 'Yükləyin'}
                    </Button>
                </div>
            </div> */}
            <div className="home-header-title-images-swiper">
                <Swiper
                    slidesPerView={'auto'}
                    spaceBetween={30}
                    pagination={{
                    clickable: true,
                    }}
                    modules={[Pagination]}
                    className="mySwiper"
                >
                    <SwiperSlide>
                        <Image
                            className="swiper-antd-image"
                            src={pc1}
                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <Image
                            className="swiper-antd-image"
                            src={pc2}
                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <Image
                            className="swiper-antd-image"
                            src={pc3}
                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <Image
                            className="swiper-antd-image"
                            src={pc4}
                        />
                    </SwiperSlide>
                </Swiper>
            </div>
        </header>

    )
}