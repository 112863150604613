import '../components/HomeVideoDoc.css'
import tutor_video from "../assets/Price Checker Documentation.mp4"

export default function HomeVideoDoc(){
    const lang = localStorage.getItem('lang')
    return(
        <section className='home_video_doc_section'>
            <div className='home_video_doc_title'>
                <h2>{lang === 'RU' ? 'Видео ' : 'Video '}<span style={{color: '#57C769'}}>{lang === 'RU' ? 'Руководство' : 'Təlimat'}</span></h2>
                <p> {lang === 'RU' ? 'Мы подготовили пошаговые инструкции, чтобы вам было легче понять и применить полученные знания для использование Price Checker' : 'Biz addım-addım təlimatlar hazırladıq ki, Price Checker istifadə etmək üçün əldə etdiyiniz bilikləri asanlıqla başa düşə və tətbiq edə biləsiniz.'}</p>
            </div>
            <div className='home_video_block'>
                <div className='home_video_element'>
                    <video
                        src={tutor_video}
                        width='100%'
                        height='100%'
                        controls
                    />
                </div>
            </div>
        </section>
    )
}