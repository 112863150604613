import { Button, Form, Input, notification } from 'antd';
import './HomeBottomTag.css';
import isValidEmail from '../utils/isValidEmail';
import { useState } from 'react';
import axios from 'axios';

export default function HomeBottomTag({openModal}){
    const lang = localStorage.getItem('lang')
    const [userEmail, setUserEmail] = useState()
    const [api, contextNotificationHolder] = notification.useNotification();
    const [loading, setLoading] = useState(false);
    const openNotification = (msg) => {
        api.info({
          message: lang === 'RU' ? `В течение 5 секунд вы будете перенаправленны на подтверждение покупки приложения` : '5 saniyə ərzində tətbiqin alınması təsdiqlənməsi üçün yönləndiriləcəksiniz.',
          placement: 'top',
        });
      };
    const validateRequestToApprove = (error) => {
        console.log(error)
        if(error === 404){
            return lang === 'RU' ? "Произошла ошибка, введённый email не зарегистрирован" : "Səhv baş verdi, daxil etdiyiniz e-poçt ünvanı qeydiyyatdan keçməyib."
        }else if(error >= 500){
            return lang === 'RU' ? `Произошла ошибка платёжной системы (код ошибки: ${error})` : `Ödəniş sistemi səhvi baş verdi (səhv kodu: ${error})`
        }else{
            return lang === 'RU' ? "Произошла ошибка, введённый email уже подтверждён" : "Səhv baş verdi, daxil etdiyiniz e-poçt təsdiq olunub."
        }
    }
    const onFinish = async (values) => {
        try {
            setLoading(true)
            const response = await axios.post('https://antinovba.az:8080/api/payment/', values);
            // openNotification()
            redirectToPay({'email': values.email})
            console.log('Response:', response.data);
        } catch (error) {
            setLoading(false)
            notification.open({
                message: 'Ошибка',
                description: validateRequestToApprove(error.request.status),
                placement: "top",
                onClick: () => {
                  console.log('Notification Clicked!');
                },
              });
        console.error(error.request.status);
        }
    };
    const redirectToPay = async (data) => {
        try{
            const response = await axios.post('https://antinovba.az:8080/api/payment/', data)
            console.log(response)
            localStorage.setItem('order_id', response.data.order_id)
            localStorage.setItem('session_id', response.data.session_id)
            setTimeout(() => {window.location.href = response.data.url;}, 10)
            //Записать order_id и session_id на local storage
        }catch(error){
            console.log(error)
        }
    }
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    const onChangeRequestMail = (e) => {
        setUserEmail(e.target.value)
    }
    return(
        <section className='bottomtag'>
            {contextNotificationHolder}
            <div className='bottomtag-container'>
                <div className='bottomtag-container-title'>
                    <div className='bottomtag-titles'>
                        <h3 className='bottomtag-title'>{lang === 'RU' ? 'Получите программу ' : 'Proqramı bu gün əldə '}<span style={{color: '#000'}}>{lang === 'RU' ? 'сегодня!' : 'edin!'}</span></h3>
                        <p className='bottomtag-subtitle'>{lang === 'RU' ? 'Не упустите шанс улучшить свой бизнес и увеличить продажи с помощью нашей программы. Зарегистрируйтесь сейчас и начните использовать все преимущества нашего инструмента для мониторинга и управления ценами в интернет-магазине. ' : 'Biznesinizi inkişaf etdirmək və satışlarınızı artırmaq şansını qaçırmayın. İndi qeydiyyatdan keçin və internet mağazasında qiymətlərin monitorinqi və idarə edilməsi alətimizin bütün üstünlüklərindən istifadə etməyə başlayın. '}</p>
                    </div>
                    <Button onClick={() => {openModal(true)}} className='bottomtag-btn pricechecker-filled-button'>{lang === 'RU' ? 'Создать аккаунт' : 'Hesab yarat'}</Button>
                </div>
                <div className='bottomtag-container-getaccess'>
                    
                <h3>{lang === 'RU' ? 'Активируйте ваш аккаунт' : 'Hesabınızı aktiv edin'}</h3>
                    <Form
                        name="basic"
                        labelCol={{
                        span: 8,
                        }}
                        wrapperCol={{
                        span: 16,
                        }}
                        style={{
                            maxWidth: 400,
                            display: 'flex',
                            marginTop: 10
                        }}
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        className='bottomtag-container-getaccess-form'
                    >
                        <Form.Item
                        style={{width: '100%'}}
                        name="email"
                        >
                        <Input placeholder='user@example.com' type="text" onChange={onChangeRequestMail} value={userEmail} name="user_email" style={{height: '55px', padding: '20px', borderRadius: '50px', width: '500px', fontSize: '20px'}} className='bottomtag-container-getaccess-form-input'/>
                        </Form.Item> 
                        <Form.Item>
                        <Button disabled={loading} className="pricechecker-filled-button bottomtag-getaccess-btn" htmlType="submit" loading={loading} style={{opacity: 1}}>
                            {lang === 'RU' ? 'Активировать' : 'Aktivləşdirin'}
                        </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </section>
    )
}