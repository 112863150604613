import React from "react";
import styles from "./Portfolio.module.css"

import step_logo from "../../assets/certificat/18424146_1186576138138190_1100743637872160878_n-removebg-preview (1).png"
import coursera_logo from "../../assets/certificat/Coursera_logo_(old).svg.png"
import edx_logo from "../../assets/certificat/edx-logo-registered-white.png"
import Card from "./Card";

import novba_logo from "../../assets/portfolio/Samsung Galaxy S21 Ultra.png"
import soft_logo from "../../assets/portfolio/Group 3.jpg"
import marines_logo from "../../assets/portfolio/Group 5.png"
import yol_logo from "../../assets/portfolio/Group 7.png"
import ckl_logo from "../../assets/portfolio/photo_2023-09-01_16-52-57.jpg"

import device_novba from "../../assets/portfolio/device_novba.png"
import device_cnk from "../../assets/portfolio/device_cnk.png"
import device_fayam from "../../assets/portfolio/device_fayam.png"
import device_soft from "../../assets/portfolio/device_soft.png"
import device_yol from "../../assets/portfolio/device_yol.png"

import soft from "../../assets/logo/firstsoft.png"
import fayam from "../../assets/logo/FIBROFAYAM.png"
import yol from "../../assets/logo/yol.png"
import cnk from "../../assets/logo/cnk.png"
import novba from "../../assets/logo/antinovba.png"

const Portfolio = () => {
    let lang = localStorage.getItem('lang')
    return(
        <div id="portfolio" className={styles.portfolio_section}>
            <div className={styles.portfolio_title}>
                <div className={styles.portfolio_left_title}>
                    <h2>{lang === "RU" ? 'Портфолио' : 'Portfolio'}</h2>
                </div>
                <div className={styles.portfolio_right_title}>
                    <h1>{lang === 'RU' ? "Если вам нужен проект, выполненный в срок, в рамках бюджета и с максимальным воздействием, то мы - команда, которая вам нужна." : 'Əgər müddəti daxilində, büdcə çərçivəsində və maksimum təsir ilə həyata keçirilmiş layihəyə ehtiyacınız varsa, o zaman biz sizə lazım olan komandayıq.'}</h1>
                    {/* <div className={styles.certificates}>
                        <img src={step_logo} alt=""/>
                        <img src={coursera_logo} alt=""/>
                        <img src={edx_logo} alt=""/>
                    </div> */}
                </div>
            </div>
            <div className={styles.cards}>
                <a target="_blank" style={{display: 'block'}} href="https://antinovba.az/" className={styles.card1}>
                    <Card title="Antinovba" subtitle={lang === "RU" ? 'Завершено' : 'Təhvil verilib'} mac={false} logo={novba} device_img={device_novba} img={novba_logo}/>      
                </a>
                <a target="_blank" style={{display: 'block'}} href="https://www.firstsoft.az/" className={styles.card2}>
                    <Card title="Firstsoft" subtitle={lang === "RU" ? 'Завершено' : 'Təhvil verilib'} mac={true} logo={soft} device_img={device_soft} img={soft_logo}/>      
                </a>
                <a target="_blank" style={{display: 'block'}} href="https://ugurluyol.az/" className={styles.card3}>
                    <Card title="Uğurlu yol" subtitle={lang === "RU" ? 'Завершено' : 'Təhvil verilib'} mac={true} logo={yol} device_img={device_yol} img={yol_logo}/>      
                </a>
                <a target="_blank" style={{display: 'block'}} href="http://fibrofayam.az/" className={styles.card4}>
                    <Card title="Fibrofayam" subtitle={lang === "RU" ? 'Завершено' : 'Təhvil verilib'} mac={true} logo={fayam} device_img={device_fayam} img={marines_logo}/>      
                </a>
                <a target="_blank" style={{display: 'block'}} href="https://canaqqalaseramik.az/" className={styles.card5}>
                    <Card title="Çanaqqala Seramik" subtitle={lang === "RU" ? 'Завершено' : 'Təhvil verilib'} mac={false} logo={cnk} device_img={device_cnk} img={ckl_logo}/>      
                </a>
            </div>
        </div>
    )
}

export default Portfolio;