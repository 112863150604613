import Azsayt from './Azsayt';
import PriceChecker from './pricechecker/src/PriceChecker';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path='/' element={<Azsayt/>}/>
        <Route path='/pricechecker' element={<PriceChecker/>}/>
      </Routes>
    </Router>
  );
}

export default App;
