export default function isValidEmail(input) {
    // Удаляем все символы, кроме цифр, чтобы выполнить проверку
    // const cleanedInput = input.replace(/\D/g, '');

    // Паттерн для шаблонов номеров телефонов
    const patterns = [
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/// Шаблон для номеров мобильных телефонов
    ];

    // Проверяем входную строку по каждому шаблону
    for (const pattern of patterns) {
        if (pattern.test(input)) {
            return true; // Найден соответствующий шаблон
        }
    }

    return false; // Ни один из шаблонов не соответствует
}
