import React from 'react';
import './App.css';
import Nav from './Nav/Nav';
import Main from './main';
import Footer from './main/footer/Footer';
import { Helmet, HelmetProvider } from 'react-helmet-async';

function Azsayt() {
  
  if(!localStorage.getItem('lang')){
    localStorage.setItem('lang', 'AZ')
  }
  return (
    <HelmetProvider>
      <div>
        <Helmet>
          <title>azsayt</title>
          <meta name="description" content="İnternet dünyasında sizin vizit kartınız." />
          <meta name="keywords" content="price checker, product price, azsayt.az, azsayt, web sayt yaratmag" />
          <link rel="canonical" href="https://azsayt.az/" />
        </Helmet>
        <Nav/>
        <div style={{paddingTop: '110px'}}>
          <Main/>
        </div>
        <Footer/>
      </div>
    </HelmetProvider>
  );
}

export default Azsayt;
