import { Anchor, Drawer, Select } from "antd";
import React, { useEffect, useState } from "react";
import styles from "./Nav.module.css"
import logo from "../assets/site/AZSayt _ Logo.png"
import {BulbOutlined, DoubleLeftOutlined, FastForwardOutlined, MenuOutlined} from "@ant-design/icons"
import { Link } from "react-router-dom";

const Nav = () => {
    const [open, setOpen] = useState(false),
        [lang, setLang] = useState(localStorage.getItem('lang'))

    const handleChange = (value) => {
        setLang(value)
        window.location.reload()
        };
    useEffect(() => {
        localStorage.setItem('lang', lang)
    }, [lang])
    return(
        <div className={styles.navigation}>
            <div className={styles.logo}>
                <img src={logo} width="146px"/>
            </div>
                {
                    lang === 'RU' ? 
                    <Anchor
                    direction="horizontal"
                    className={styles.anchors}
                    items={[
                        {
                            key: 'home',
                            href: '#home',
                            title: 'Главная',
                        },
                        {
                            key: 'services',
                            href: '#services',
                            title: 'Услуги',
                        },
                        {
                            key: 'portfolio',
                            href: '#portfolio',
                            title: 'Работы',
                        },
                        {
                            key: 'contacts',
                            href: '#contacts',
                            title: 'Поддержка',
                        },
                        {
                            key: 'pricechecker',
                            href: '/pricechecker',
                            title: 'Price Checker',
                        }
                    ]}
                /> :
                <Anchor
                    direction="horizontal"
                    className={styles.anchors}
                    items={[
                        {
                            key: 'home',
                            href: '#home',
                            title: 'Əsas',
                        },
                        {
                            key: 'services',
                            href: '#services',
                            title: 'Xidmətlər',
                        },
                        {
                            key: 'portfolio',
                            href: '#portfolio',
                            title: 'Portfolio',
                        },
                        {
                            key: 'contacts',
                            href: '#contacts',
                            title: 'Əlaqə',
                        },
                        {
                            key: 'pricechecker',
                            href: '',
                            title: <a href="/pricechecker">Price Checker</a>,
                        }
                    ]}
                />
                }
            <div>
                <div className={styles.burger_side}>
                    <a href="#intro"><BulbOutlined style={{fontSize: '1.5em'}} /></a>
                    <Select
                        className={styles.lang}
                        defaultValue={localStorage.getItem('lang')}
                        style={{
                            width: 90,
                            border: 0
                        }}
                        onChange={handleChange}
                        options={[
                            {
                            value: 'AZ',
                            label: 'AZ',
                            },
                            {
                            value: 'RU',
                            label: 'RU',
                            }
                        ]}
                        />
                    <MenuOutlined className={styles.burger} onClick={() => {setOpen(true)}}/>
                </div>
                <Drawer className={styles.nav_burger} title={<h2 style={{fontSize: '1.6em', fontWeight: '500'}}>Меню</h2>} placement="right" onClose={() => {setOpen(false)}} open={open}>
                <ul className={styles.burger_contacts}>
                    <li>Instagram</li>
                    <li>Linkedin</li>
                    <li>WhatsApp</li>
                </ul>
                {
                    lang === 'RU' ? 
                    <Anchor
                    className={styles.mob_anchor}
                    items={[
                        {
                            key: 'home',
                            href: '#home',
                            title: 'Главная',
                        },
                        {
                            key: 'services',
                            href: '#services',
                            title: 'Услуги',
                        },
                        {
                            key: 'portfolio',
                            href: '#portfolio',
                            title: 'Работы',
                        },
                        {
                            key: 'contacts',
                            href: '#contacts',
                            title: 'Поддержка',
                        },
                        {
                            key: 'pricechecker',
                            href: '',
                            title: <a href="/pricechecker">Price Checker</a>,
                        }
                    ]}
                /> :
                <Anchor
                    className={styles.mob_anchor}
                    items={[
                        {
                            key: 'home',
                            href: '#home',
                            title: 'Əsas',
                        },
                        {
                            key: 'services',
                            href: '#services',
                            title: 'Xidmətlər',
                        },
                        {
                            key: 'portfolio',
                            href: '#portfolio',
                            title: 'Portfolio',
                        },
                        {
                            key: 'contacts',
                            href: '#contacts',
                            title: 'Əlaqə',
                        },
                        {
                            key: 'pricechecker',
                            href: '',
                            title: <a href="/pricechecker">Price Checker</a>,
                        }
                    ]}
                />
                }
                </Drawer>
            </div>
        </div>
    )
}

export default Nav;