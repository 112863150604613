import React from "react";
import Header from "./header/Header";
import Partners from "./partners/Partners";
import Introduction from "./introduction/Introduction";
import Portfolio from "./portfolio/Portfolio";
import BPartner from "./become_partner/BPartner";
import Footer from "./footer/Footer";

const Main = () => {
    return(
        <div>
            <Header/>
            {/* <Partners/> */}
            <Introduction/>
            <Portfolio/>
            <BPartner/>
        </div>
    )
}

export default Main