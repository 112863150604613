import { Button, Form, message, Input, Modal } from "antd";
import { useEffect, useState } from "react";
import isValidPhoneNumber from "../../utils/isValidPhoneNumber";
import isValidEmail from "../../utils/isValidEmail";
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

export default function ModalFooter({open, setOpen}){
    const [messageApi, contextHolder] = message.useMessage();
    const [userPhoneNumber, setUserPhoneNumber] = useState()
    const [userEmail, setUserEmail] = useState()
    const [loading, setLoading] = useState(false);
    const success = () => {
        messageApi.open({
            type: 'success',
            content: 'Ваша заявка была отправлена, мы с вами свяжемся в скором времени!',
        });
    };
    const error = () => {
        messageApi.open({
            type: 'error',
            content: 'Произошла ошибка, попробуйте отправить заявку позже',
        });
    };
    const handleOk = () => {
        setTimeout(() => {
          setOpen(false);
        }, 3000);
      };
    const handleCancel = () => {    
        setOpen(false);
    };
    const onFinish = (values) => {
        sendEmail(values)
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    const onChangeRequestNumber = (e) => {
        setUserPhoneNumber(e.target.value)
    }
    const onChangeRequestMail = (e) => {
        setUserEmail(e.target.value)
    }
    const form = useRef();
    const sendEmail = (values) => {
        setLoading(true)
        const formElement = document.createElement('form');

        // Добавляем скрытое поле для каждого свойства объекта
        Object.entries(values).forEach(([name, value]) => {
            const inputElement = document.createElement('input');
            inputElement.type = 'hidden';
            inputElement.name = name;
            inputElement.value = value;
            formElement.appendChild(inputElement);
        });

        // Добавляем форму на страницу, чтобы сработал метод sendForm
        formElement.style.display = 'none';
        document.body.appendChild(formElement);

        emailjs.sendForm('service_o8u3ulp', 'template_fnq7vsa', formElement, 'HQ6451BoYq09vFiOj')
            .then((result) => {
                setOpen(false)
                setLoading(false)
                success()
            }, (error) => {
                error()
            });

        // Удаляем временную форму сразу после отправки
        document.body.removeChild(formElement);
    };
    
    return(
        <Modal
            open={open}
            title="Оставьте заявку"
            onOk={handleOk}
            onCancel={handleCancel}
            footer={null}
            width={700}
        >
            {contextHolder}
            <Form
                ref={form}
                name="basic"
                labelCol={{
                span: 8,
                }}
                wrapperCol={{
                span: 16,
                }}
                style={{
                    maxWidth: 500,
                    margin: '0 auto',
                    padding: '40px 0'
                }}
                initialValues={{
                remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                label="Ваше имя"
                name="user_name"
                rules={[
                    {
                        required: true,
                        message: 'Введите ваше имя',
                    }
                ]}
                >
                <Input type="text" name="user_name"/>
                </Form.Item>
                <Form.Item
                label="Телефонный номер"
                name="user_phone"
                rules={[
                    {
                        required: true,
                        message: 'Введите номер вашего телефона',
                    },
                    {
                        validator: !isValidPhoneNumber(userPhoneNumber),
                        message: 'Не правильный формат'
                    }
                ]}
                >
                <Input type="number" onChange={onChangeRequestNumber} value={userPhoneNumber} name="user_phone"/>
                </Form.Item>

                <Form.Item
                label="Электронный адрес"
                name="user_email"
                rules={[
                    {
                        required: true,
                        message: 'Укажите e-mail',
                    },
                    {
                        validator: !isValidEmail(userEmail),
                        message: 'Не правильный формат'
                    }
                ]}
                >
                <Input type="text" onChange={onChangeRequestMail} value={userEmail} name="user_email"/>
                </Form.Item>
                <Form.Item
                wrapperCol={{
                    offset: 8,
                    span: 16,
                }}
                >
                <Button 
                loading={loading} style={{
                    backgroundColor: 'black',
                    color: '#fff'
                }} htmlType="submit">
                    Отправить
                </Button>
                </Form.Item>
            </Form>
        </Modal>
    )
}