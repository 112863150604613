import { useEffect, useState } from 'react'
import './HomeFeatures.css'

const features_data = (lang = localStorage.getItem('lang')) => {
    if(lang === 'RU'){
        return [
            {
                tab: 'Возможности',
                active: true,
                id: 0,
                inner_tabs: [
                    {
                        title: 'Парсинг цен',
                        text: 'Наша программа автоматически сканирует цены в интернет-магазине и сортирует их от дешевых к дорогим, предоставляя вам удобный список для анализа'
                    },
                    {
                        title: 'Регистрация и авторизация',
                        text: 'Продавцы могут легко зарегистрироваться и авторизоваться в системе, чтобы получить доступ к своим данным и инструментам управления.'
                    },
                    {
                        title: 'Контроль цен',
                        text: 'Удобный интерфейс позволяет быстро и легко контролировать и изменять цены на ваши товары. '
                    },
                    {
                        title: '"Выиграть цену"',
                        text: 'Наш уникальный алгоритм позволяет вам автоматически менять цены, чтобы всегда оставаться впереди конкурентов.'
                    },
                ]
            },
            {
                tab: 'Преимущества',
                active: false,
                id: 1,
                inner_tabs: [
                    {
                        title: 'Экономия времени',
                        text: 'Автоматизация рутинных задач позволяет вам сосредоточиться на более важных аспектах бизнеса. '
                    },
                    {
                        title: 'Конкурентоспособность',
                        text: 'С функцией "Выиграть цену" вы всегда будете знать, что ваши цены конкурентоспособны.'
                    },
                    {
                        title: 'Удобство',
                        text: 'Интуитивно понятный интерфейс делает управление ценами простым и быстрым. '
                    },
                    {
                        title: 'Безопасность',
                        text: 'Все данные передаются и хранятся с использованием современных технологий безопасности. '
                    },
                ],
            },
            {
                tab: 'Поддержка',
                active: false,
                id: 2,
                inner_tabs: [
                    {
                        title: 'Поддержка',
                        text: 'Наша служба поддержки доступна, готовая помочь в любое время. Мы ценим ваше время и стремимся предоставить оперативные и качественные решения.'
                    },
                    {
                        title: 'Многоязычная Поддержка',
                        text: 'Наша команда говорит на разных языках для удобства общения. Мы оперативно реагируем на запросы, обеспечивая эффективное решение проблем. Ваши отзывы помогают нам совершенствоваться.'
                    },
                    {
                        title: 'Точные и Актуальные Данные',
                        text: 'Мы гарантируем быстрый ответ на ваши запросы, чтобы минимизировать время ожидания и обеспечить эффективное решение вашей проблемы в кратчайшие сроки.'
                    },
                    {
                        title: 'Обратная Связь и Улучшения',
                        text: 'Ваши отзывы помогают нам совершенствовать сервис, поэтому мы всегда открыты к вашим комментариям и предложениям.'
                    },
                ],
            }
        ]
    }else{
        return [
            {
                "tab": "İmkanlar",
                "active": true,
                "id": 0,
                "inner_tabs": [
                    {
                        "title": "Qiymətlərin parslanması",
                        "text": "Proqramımız avtomatik olaraq internet mağazasında qiymətləri skan edir və onları ucuzdan bahaya doğru sıralayaraq sizə rahat siyahı təqdim edir."
                    },
                    {
                        "title": "Qeydiyyat və avtorizasiya",
                        "text": "Satıcılar, öz məlumatlarına və idarəetmə alətlərinə giriş əldə etmək üçün asanlıqla qeydiyyatdan keçə və avtorizasiya edə bilərlər."
                    },
                    {
                        "title": "Qiymətlərin idarə edilməsi",
                        "text": "Rahat interfeys, mallarınızın qiymətlərini tez və asanlıqla idarə etməyə imkan verir."
                    },
                    {
                        "title": '"Qiyməti qazan"',
                        "text": "Unikal alqoritmimiz, rəqiblərdən həmişə bir addım öndə olmaq üçün qiymətlərinizi avtomatik olaraq dəyişməyə imkan verir. "
                    }
                ]
            },
            {
                "tab": "Üstünlüklər",
                "active": false,
                "id": 1,
                "inner_tabs": [
                    {
                        "title": "Vaxta qənaət",
                        "text": "Rutin tapşırıqların avtomatlaşdırılması sizə biznesin daha vacib aspektlərinə diqqət yetirməyə imkan verir"
                    },
                    {
                        "title": "Rəqabət qabiliyyəti",
                        "text": '"Qiyməti qazan" funksiyası ilə qiymətlərinizin hər zaman rəqabət qabiliyyətli olduğuna əmin olacaqsınız.'
                    },
                    {
                        "title": "Rahatlıq",
                        "text": "İntuitiv interfeys qiymətlərin idarə edilməsini sadə və sürətli edir."
                    },
                    {
                        "title": "Təhlükəsizlik",
                        "text": "Bütün məlumatlar müasir təhlükəsizlik texnologiyalarından istifadə edərək ötürülür və saxlanılır. "
                    }
                ]
            },
            {
                "tab": "Dəstək",
                "active": false,
                "id": 2,
                "inner_tabs": [
                    {
                        "title": "Dəstək",
                        "text": "Dəstək xidmətimiz mövcuddur və hər zaman kömək etməyə hazırdır. Vaxtınızı qiymətləndiririk və operativ və keyfiyyətli həllər təqdim etməyə çalışırıq."
                    },
                    {
                        "title": "Çoxdilli Dəstək",
                        "text": "Komandamızın üzvləri müxtəlif dillərdə danışırlar ki, ünsiyyətiniz daha rahat olsun. Sorğulara operativ cavab veririk və problemlərinizi effektiv həll edirik. Fikirləriniz bizə inkişaf etməyə kömək edir."
                    },
                    {
                        "title": "Dəqiq və Güncəl Məlumatlar",
                        "text": "Sorğularınıza tez cavab verməyə çalışırıq ki, gözləmə vaxtını minimallaşdıraq və probleminizin qısa zamanda effektiv həll olunmasını təmin edək."
                    },
                    {
                        "title": "Əlaqə və Təkmilləşdirmələr",
                        "text": "Fikirləriniz xidmətimizi təkmilləşdirməyə kömək edir, buna görə də şərh və təkliflərinizə hər zaman açığıq."
                    }
                ]
            }
        ]
    }
}

export default function HomeFeatures(){
    const lang = localStorage.getItem('lang')
    const [activeTab, setActiveTab] = useState(0)
    const [tabs, setTabs] = useState(features_data())
    const updatedTabs = (newId) => {
        const newTabs = tabs.map(tab => {
            if (tab.id === newId) {
                return { ...tab, active: true };
            } else {
                return { ...tab, active: false };
            }
        });
        setTabs(newTabs)
    }
    const selectTab = (id) => {
        if(id === activeTab) return
        setActiveTab(id)
        updatedTabs(id)
    }   
    return(
        <section className='features'>
            <div className='features-title'>
                <h2>{lang === 'RU' ? 'Наши ' : 'Bizim '}<span style={{color: '#57C769'}}>{lang === 'RU' ? 'Особенности' : 'Xüsusiyyətlərimiz'}</span></h2>
                <p> {lang === 'RU' ? 'Мы предлагаем сравнения цен на разнообразные товары и услуги, от электроники до путешествий, чтобы вы могли найти лучшие предложения в одном месте.' : 'Biz müxtəlif məhsul və xidmətlər, elektronika məhsullarından tutmuş səyahət xidmətlərinə qədər qiymət müqayisələri təklif edirik ki, siz ən yaxşı təklifləri bir yerdə tapa biləsiniz.'}</p>
            </div>
            <div className='features-features-container'>
                <div className='features-features-tabs-btns'>
                    {
                        tabs.map((item, index) => {
                            return (
                                <div key={item.tab} onClick={() => {selectTab(item.id)}} className={`features-features-tabs-btn ${item.active ? 'features-features-tabs-btn-selected' : ''}`}>{item.tab}</div>
                            )
                        })
                    }
                </div>
                <div className='features-features'>
                    {
                        tabs[activeTab].inner_tabs.map((item, index) => {
                            return(
                                <div key={item.title} className='features-feature-item'>
                                    <h3>{item.title}</h3>
                                    <p>
                                        {item.text}
                                    </p>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </section>
    )
}