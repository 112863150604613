export default function isValidPhoneNumber(input) {
    // Удаляем все символы, кроме цифр, чтобы выполнить проверку
    // const cleanedInput = input.replace(/\D/g, '');

    // Паттерн для шаблонов номеров телефонов
    const patterns = [
        /^(\+994|994)(50|51|55|70|77|99|050|051|055|070|077|099)\d{7}$/ // Шаблон для номеров мобильных телефонов
    ];

    // Проверяем входную строку по каждому шаблону
    for (const pattern of patterns) {
        if (pattern.test(input)) {
            return true; // Найден соответствующий шаблон
        }
    }

    return false; // Ни один из шаблонов не соответствует
}
