import { Button } from 'antd';
import './Navbar.css';
import logo from "../assets/logo.png"
import { LoginOutlined, LogoutOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

export default function Navbar({openModal, openModalLogin}){
    let lang = localStorage.getItem('lang')

    return(
        <nav className='navbar'>
            <div className='navbar-logo-container'>
                <img className='app-logo' src={logo} alt='logo'/>
                <span className='app-logo-title'>Price Checker</span>
            </div>
            <div className='navbar-sign'>
                <Link to="/"><Button type='text' shape='round' className='pricechecker-filled-button navbar-btn navbar-backto' onClick={() => {}}><LogoutOutlined className='navbar-btn-ico'/><span className='mob-btn-txt'>azsayt</span></Button></Link>
                <Button shape='round' className='pricechecker-filled-button navbar-btn' onClick={() => {openModal(true)}}><LoginOutlined className='navbar-btn-ico'/><span className='mob-btn-txt-start'>{ lang === 'AZ' ? 'Başlamaq' : 'Начать'}</span></Button>
            </div>
        </nav>
    )
}