import React, { useState } from "react";
import styles from "./Footer.module.css"
import Faq from "../faq/faq";
import ModalFooter from "./ModalFooter";

const Footer = () => {
    const lang = localStorage.getItem('lang')
    const [openModal, setOpenModal] = useState(false);
    return(
        <footer id="contacts" className={styles.footer}>
            <ModalFooter open={openModal} setOpen={setOpenModal}/>
            <div className={styles.footer_contact}>
                <h1>{lang === 'RU' ? "Оставьте Заявку" : "Tələbinizi qoyun."}</h1>
                <p>{lang === 'RU' ? 'Работайте с нами, рост вашего бизнеса: наше Решение' : 'Bizimlə işləyin, işinizin inkişafını təmin edin'}</p>
                <div className={styles.footer_btns}>
                    <a onClick={() => {setOpenModal(true)}}><div>{lang === 'RU' ? 'Свяжитесь с нами' : 'Bizlə əlaqə saxlayın'}</div></a>
                    <a href="https://goo.gl/maps/3CVvH3TksZLuL3D17" target="_blank"><div>{lang === 'RU' ? 'Локация' : 'Lokasiya'}</div></a>
                </div>
            </div>
            <div className={styles.footer_links}>
                <ul>
                    <li className={styles.list_loc}>
                        <p>Baku</p>
                        <a href="mail:firstsoft@info.az">info@azsayt.az</a>
                        <p>Əhməd Rəcəbli 255</p>
                        <a href="tel:+994512069478">{lang === 'RU' ? '+994512069478' : '+994512069478'}</a>
                        <p>AZ</p>
                    </li>
                    <li className={styles.list_nav}>
                        <a href="#home">{lang === 'RU' ? 'Домой' : 'Əsas səhfə'}</a>
                        <a href="#portfolio">{lang === 'RU' ? 'Портфолио' : 'Portfolio'}</a>
                        <a href="#services">{lang === 'RU' ? 'Услуги' : 'Xidmətlər'}</a>
                    </li>
                    <li className={styles.list_nav}>
                        <a href="#">Linkedin</a>
                        <a href="#">Instagram</a>
                        <a href="https://wa.me/+994512069478" target="_blank" rel="noopener noreferrer">WhatsApp</a>
                        <a href="tel:+994512069478">{lang === 'RU' ? 'Свяжитесь с нами' : 'Əlaqə saxlayın'}</a>
                    </li>
                </ul>
                <div className={styles.map}>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d759.4047675613683!2d49.857381942320885!3d40.417288846513905!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x403087fdbc9753fb%3A0x1f116eda50acb1ce!2zQ1Y5NCsyUjUsIEJha8Sx!5e0!3m2!1sru!2saz!4v1694429841099!5m2!1sru!2saz" width="600" style={{filter: 'invert(90%)', border: 0}} height="450" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" ></iframe>
                </div>
            </div>
        </footer>
    )
}

export default Footer