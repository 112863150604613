import { Button, Form, message, Input, Modal } from "antd";
import { useEffect, useState } from "react";
import isValidPassword from "../utils/isValidPassword";
import isValidEmail from "../utils/isValidEmail";

export default function ModalLogin({open, setOpen}){
    const [messageApi, contextHolder] = message.useMessage();
    const [userPhoneNumber, setUserPhoneNumber] = useState()
    const [userEmail, setUserEmail] = useState()
    const [loading, setLoading] = useState(false);
    const success = () => {
        messageApi.open({
            type: 'success',
            content: 'Ваша заявка была отправлена, мы с вами свяжемся в скором времени!',
        });
    };
    const error = () => {
        messageApi.open({
            type: 'error',
            content: 'Произошла ошибка, попробуйте отправить заявку позже',
        });
    };
    const handleOk = () => {
        setTimeout(() => {
          setOpen(false);
        }, 3000);
      };
    const handleCancel = () => {    
        setOpen(false);
    };
    const onFinish = (values) => {
        console.log(values)
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    const onChangeRequestNumber = (e) => {
        setUserPhoneNumber(e.target.value)
    }
    const onChangeRequestMail = (e) => {
        setUserEmail(e.target.value)
    }
    return(
        <Modal
            open={open}
            title="Вход"
            onOk={handleOk}
            onCancel={handleCancel}
            footer={null}
            width={700}
        >
            {contextHolder}
            <Form
                name="basic"
                labelCol={{
                span: 8,
                }}
                wrapperCol={{
                span: 16,
                }}
                style={{
                    maxWidth: 500,
                    margin: '0 auto',
                    padding: '40px 0'
                }}
                initialValues={{
                remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                label="Электронный адрес"
                name="user_email"
                rules={[
                    {
                        required: true,
                        message: 'Укажите e-mail',
                    },
                ]}
                >
                <Input type="text" onChange={onChangeRequestMail} value={userEmail} name="user_email"/>
                </Form.Item> 
                <Form.Item
                label="Пароль"
                name="user_phone"
                rules={[
                    {
                        required: true,
                        message: 'Введите пароль',
                    },
                ]}
                >
                <Input.Password onChange={onChangeRequestNumber} value={userPhoneNumber} name="user_phone"/>
                </Form.Item>
                <Form.Item
                wrapperCol={{
                    offset: 8,
                    span: 16,
                }}
                >
                <Button className="pricechecker-filled-button" loading={loading} htmlType="submit" >
                    Вход
                </Button>
                </Form.Item>
            </Form>
        </Modal>
    )
}