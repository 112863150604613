import { Card, Col, Row } from "antd";
import React from "react";
import styles from "./Introduction.module.css"
import img1 from "../../assets/site/photo-1587382926858-4a0dd19eb252 1.jpg"
import img2 from "../../assets/site/photo-1575140685026-1908fb64d279 1.jpg"
import Faq from "../faq/faq";
import { IdcardOutlined, MedicineBoxOutlined, MonitorOutlined, PhoneOutlined, RiseOutlined, SketchOutlined, ThunderboltOutlined } from "@ant-design/icons";


const Introduction = () => {
    const lang = localStorage.getItem('lang')
    return(
        <div id="intro">
            <div className={styles.introduction}>
                <div className={styles.intro_title}>
                    <h1>{lang === 'RU' ? 'Почему мы?' : 'Niyə biz?'}</h1>
                    {/* <h1>{lang === 'RU' ? 'Мы партнер, который готов вложить свой опыт и знания в ваш успех. С нами ваш проект – в надежных руках.' : 'Biz, sizi uğura çatdırmağa öz təcrübə və biliklərini qoyacaq bir tərəfdaşıq. Bizimlə sizin layihəniz - təhlükəsiz əllərdədir.'}</h1> */}
                </div>
                <div className={styles.intro_cards}>
                    {
                        lang === 'RU' ? <div className={styles.intro_cards_flex}>
                        <Card className={styles.ant_card} hoverable={true} title={<h6 style={{ fontWeight: '500' }}><ThunderboltOutlined/> Знания</h6>} bordered={false}>
                            <h5>Опыт и профессионализм</h5>
                            <p>Наша компания является долгосрочным игроком на рынке. Мы понимаем нюансы создания сайтов и мобильных приложений, что позволяет нам предлагать передовые решения.</p>
                        </Card>
                        <Card className={styles.ant_card} hoverable={true} title={<h6 style={{ fontWeight: '500' }}><SketchOutlined /> Уникальность</h6>} bordered={false}>
                            <h5>Индивидуальный подход</h5>
                            <p>Мы верим, что каждый проект уникален. Мы глубоко вникаем в потребности клиента и его бизнес-цели, чтобы предоставить наилучшее решение, отвечающее вашим требованиям.</p>
                        </Card>
                        <Card className={styles.ant_card} hoverable={true} title={<h6 style={{ fontWeight: '500' }}><MedicineBoxOutlined />Услуги</h6>} bordered={false}>
                            <h5>Комплексный подход</h5>
                            <p>Мы предоставляем полный спектр услуг – от концепции и дизайна до разработки и поддержки. Это позволяет нам обеспечивать вас всесторонней помощью на каждом этапе.</p>
                        </Card>
                        <Card className={styles.ant_card} hoverable={true} title={<h6 style={{ fontWeight: '500' }}><MonitorOutlined /> Тенденции</h6>} bordered={false}>
                            <h5>Инновации и технологии</h5>
                            <p>Мы всегда в курсе последних тенденций в веб- и мобильной разработке. Мы стремимся применять новые технологии и инструменты, чтобы ваш сайт или приложение были современными и эффективными.</p>
                        </Card>
                        <Card className={styles.ant_card} hoverable={true} title={<h6 style={{ fontWeight: '500' }}><RiseOutlined /> Успех</h6>} bordered={false}>
                            <h5>Довольные клиенты</h5>
                            <p>Наши успешные проекты и долгосрочные отношения с клиентами – лучшее свидетельство качества нашей работы. Мы гордимся своей репутацией и стремимся превзойти ожидания каждого клиента.</p>
                        </Card>
                        <Card className={styles.ant_card} hoverable={true} title={<h6 style={{ fontWeight: '500' }}><PhoneOutlined /> Коммуникация</h6>} bordered={false}>
                            <h5>Прозрачность и сотрудничество</h5>
                            <p>Мы ценим честность и открытость. Мы поддерживаем прозрачные процессы и регулярную коммуникацию с клиентами, чтобы обеспечить вас всей необходимой информацией.</p>
                        </Card>
                    </div> : 
                    <div className={styles.intro_cards_flex}>
                        <Card className={styles.ant_card} hoverable={true} title={<h6 style={{ fontWeight: '500' }}><ThunderboltOutlined/> Bilik</h6>} bordered={false}>
                            <h5>Təcrübə və peşəkarlıq</h5>
                            <p>Bizim şirkət uzunmüddətli bir oyunçu olub veb saytlar və mobil tətbiqlərin yaradılmasının nuanslarını başa düşürük, bu da bizə ən son təkliflər sunmağa imkan verir.</p>
                        </Card>
                        <Card className={styles.ant_card} hoverable={true} title={<h6 style={{ fontWeight: '500' }}><SketchOutlined /> Unikal</h6>} bordered={false}>
                            <h5>Fərdi yanaşma</h5>
                            <p>Hər bir layihənin fərdi olduğuna inanırıq. Biz müştərinin ehtiyaclarına və iş məqsədlərinə dərin nüfuz etməyə və tələblərinizi qarşılayan ən yaxşı həlli təqdim etməyə çalışırıq.</p>
                        </Card>
                        <Card className={styles.ant_card} hoverable={true} title={<h6 style={{ fontWeight: '500' }}><MedicineBoxOutlined /> Xidmətlər</h6>} bordered={false}>
                            <h5>Tam yanaşma</h5>
                            <p>Biz tam xidmət spektri təqdim edirik - kontseptsiyadan dizayna, inkişafdan dəstəyə qədər. Bu, hər mərhələdə sizi bütün tərəfdaşlıkla təmin etməyə imkan verir.</p>
                        </Card>
                        <Card className={styles.ant_card} hoverable={true} title={<h6 style={{ fontWeight: '500'}}><MonitorOutlined/> Tərəqqilər və <br/> Texnologiyalar</h6>} bordered={false}>
                            <h5>İnnovasiyalar və texnologiyalar</h5>
                            <p>Biz həmişə veb və mobil inkişafın son tərəqqilərindəyik. Biz yeni texnologiyalardan və alətlərdən istifadə etməyə çalışırıq ki, veb saytınız və ya tətbiqiniz müasir və effektiv olsun.</p>
                        </Card>
                        <Card className={styles.ant_card} hoverable={true} title={<h6 style={{ fontWeight: '500' }}><RiseOutlined /> Uğur</h6>} bordered={false}>
                            <h5>Məmnun müştərilər</h5>
                            <p>Uğurlu layihələrimiz və müştəri ilə uzunmüddətli əlaqələrimiz bizim işimizin keyfiyyətinin ən yaxşı təsdiqçisidir. Biz öz rəyçilərimizlə gurur duyuruq və hər bir müştərinin gözləntilərini üstünləşdirməyə çalışırıq.</p>
                        </Card>
                        <Card className={styles.ant_card} hoverable={true} title={<h6 style={{ fontWeight: '500' }}><PhoneOutlined /> Əlaqə</h6>} bordered={false}>
                            <h5>Şəffaf və iştirak</h5>
                            <p>Mərhələsiz prosesləri və müştərilərlə düzgün əlaqəni qiymətləndiririk. Sizi lazım olan bütün məlumatlarla təmin etmək üçün şəffaf prosesləri və müxtəlif əlaqələrə dəstək veririk.</p>
                        </Card>
                    </div>
                    }
                </div>
            </div>
            <div id="services" className={`${styles.introduction2}`}>
                <div className={styles.introduction_list_block}>
                    <div className={`${styles.intro_title} ${styles.intro_title_img}`}>
                        <img src={img1} style={{width: '70%', borderRadius: '15px'}}/>
                    </div>
                    <div className={styles.intro_services}>
                        <h1>{lang === 'RU' ? 'Наша компания работает над обеспечением вашего успеха в создании, развитии и управлении интернет-магазинами. Интернет-магазины - важный инструмент для увеличения вашей эффективности и дальнейшего развития вашего бизнеса в сфере онлайн-продаж' : 'Şirkətimiz internet mağazalarının yaradılması, inkişaf etdirilməsi və tənzimlənməsi sahəsində müvəffəqiyyətinizi təmin etmək üçün çalışır. İnternet mağazaları, sizin nailiyyətinizi artırmağınız və onlayn satış sahəsində təcrübənizi daha da inkişaf etdirməyiniz üçün əhəmiyyətli bir vasitədir'}</h1>
                        {
                            lang === 'RU' ? 
                            <div className={styles.introduction_list}>
                                <ul>
                                    <li>Идея и концепция</li>
                                    <li>Платформа и технологии</li>
                                    <li>UI/UX</li>
                                </ul>
                                <ul>
                                    <li>Маркетинг и продвижение</li>
                                    <li>Обратная связь и обновления</li>
                                    <li>Запуск и оптимизация</li>
                                </ul>
                            </div> :
                            <div className={styles.introduction_list}>
                                <ul>
                                    <li>Fikir və Konsept</li>
                                    <li>Platforma və Texnologiyalar</li>
                                    <li>UI/UX</li>
                                </ul>
                                <ul>
                                    <li>Marketinq və Təbliğat</li>
                                    <li>Tərəqqi və Yeniləmələr</li>
                                    <li>İşə salma və Optimalizasiya</li>
                                </ul>
                            </div>
                        }
                        {/* <div className={styles.all_services}>
                            Все услуги
                        </div> */}
                    </div>
                </div>
            </div>
            {/* <section className={styles.text_devider}>
                <div className={styles.scroll}>
                    <div className={styles.text}>
                    Life is too short to be small. Life is too short to be small. Life is too short to be small 
                    </div>
                    <div className={styles.text}>
                    Life is too short to be small. Life is too short to be small. Life is too short to be small 
                    </div>
                </div>
            </section> */}
            <div className={`${styles.introduction3}`}>
                <div className={styles.introduction_list_block}>
                    <div className={`${styles.intro_title} ${styles.intro_title_img} ${styles.intro_title_img_2}`}>
                        <img src={img2} style={{width: '70%', borderRadius: '15px'}}/>
                    </div>
                    {
                        lang === 'RU' ? 
                        <div className={styles.intro_services}>
                        <h1>Наша компания работает для вашего успеха в создании, развитии и настройке мобильных приложений. Мобильные приложения - важный инструмент для повышения эффективности вашего бизнеса и улучшения опыта клиентов</h1>
                        <div className={styles.introduction_list}>
                            <ul>
                                <li>Индивидуальный Подход</li>
                                <li>Технологический Стек</li>
                                <li>UI/UX</li>
                            </ul>
                            <ul>
                                <li>Опыт и Экспертиза</li>
                                <li>Рост и Масштабирование</li>
                                <li>Тестирование и Оптимизация</li>
                            </ul>
                        </div>
                        {/* <div className={styles.all_services}>
                            Все услуги
                        </div> */}
                    </div> :
                    <div className={styles.intro_services}>
                        <h1>Şirkətimiz mobil tətbiqlərin yaradılması, inkişaf etdirilməsi və tənzimlənməsi sahəsində müvəffəqiyyətiniz üçün işləyir. Mobil tətbiqlər, şirkətinizin nailiyyətini artırmaq və müştəri təcrübəsini yaxşılaşdırmaq üçün əhəmiyyətli bir vasitədir.</h1>
                        <div className={styles.introduction_list}>
                            <ul>
                                <li>Fərdi Yanaşma</li>
                                <li>Texnologiya Stack</li>
                                <li>UI/UX</li>
                            </ul>
                            <ul>
                                <li>Təcrübə və İxtisas</li>
                                <li>Genişlənmə və Qabaqlama</li>
                                <li>Sınaq və Optimalizasiya</li>
                            </ul>
                        </div>
                    </div>
                    }
                </div>
            </div>
            <section className={styles.faq_section}>
                <h2>{lang === 'RU' ? 'Часто задаваемые вопросы' : 'Tez-tez verilən suallar'}</h2>
                <Faq/>
            </section>
        </div>
    )
}

export default Introduction;