import React from "react";
import styles from "./Header.module.css"
import head_bg from "../../assets/site/photo-1678436749168-0711baa17e171.webp"
import video from "../../assets/video/ezgif.com-video-to-gif.gif"

const Header = () => {
    const lang = localStorage.getItem('lang')
    return(
        <div id="home" className={styles.header} style={{backgroundImage: `url("${video}")`}}>
            <div className={styles.header_title}>
                <h1>{lang === 'RU' ? 'Ваша визитка в интернет-пространстве' : 'İnternet dünyasında sizin vizit kartınız'}</h1>
                {/* {lang === 'RU' ? <h1>Инновационные решения для вашего<br/> успешного онлайн присутствия</h1> : <h1>Sizin uğurlu onlayn mövcudluğunuz üçün innovativ həllər</h1>} */}
                <a href="#portfolio" className={styles.to_portfolio}>{lang === 'RU' ? 'Наши работы' : 'Bizim işlərimiz'}</a>
            </div>
        </div>
    )
}

export default Header;