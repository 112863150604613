import React from "react";
import styles from "./BPartner.module.css"

import c1_logo from "../../assets/partners/mobi.png"
import btr_logo from "../../assets/partners/Bitrix24.png"
import adb_logo from "../../assets/partners/Adobe.png"
import ag_logo from "../../assets/partners/agent.png"
import ks_logo from "../../assets/partners/Kaspersky.png"
import mc_logo from "../../assets/partners/Microsoft.png"

const BPartner = () => {
    const lang = localStorage.getItem('lang')
    return(
        <div className={styles.partner_section}>
            <div className={styles.partners_title}>
                <h2>{lang === 'RU' ? 'Партнёрство' : 'Partnerlik'}</h2>
            </div>
            <div>
                <h1>{lang === 'RU' ? 'Мы сотрудничаем с лучшими профессионалами в отрасли, наши клиенты всегда на передовых позициях.' : 'Biz sənayenin ən yaxşı mütəxəssisləri ilə əməkdaşlıq edirik, müştərilərimiz həmişə öncü mövqedədir.'}</h1>
                <div className={styles.partner_logos}>
                    <img src={adb_logo} alt=""/>
                    <img src={ks_logo} alt=""/>
                    <img src={mc_logo} alt=""/>
                </div>
                {/* <div className={styles.partner_btn}>Стать партнёром</div> */}
            </div>
        </div>
    )
}

export default BPartner; 