import HomeHeader from "./components/HomeHeader";
import Navbar from "./components/Navbar";
import './App.css'
import HomeFeatures from "./components/HomeFeatures";
import HomeBottomTag from "./components/HomeBottomTag";
import Footer from "./components/Footer";
import { useEffect, useState } from "react";
import ModalRegister from "./components/ModalRegister";
import ModalLogin from "./components/ModalLogin";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import HomeVideoDoc from "./components/HomeVideDoc";
import { Helmet, HelmetProvider } from "react-helmet-async";

export default function PriceChecker(){
    const [openModal, setOpenModal] = useState(false); 
    const [openModalLogin, setOpenModalLogin] = useState(false);
    const params = window.location.search;
    const urlParams = new URLSearchParams(params);
    let lang = localStorage.getItem('lang')

    useEffect(() => {
        if(urlParams.get('status')){
            try{
                console.log('asd')
                // axios.get(`https://antinovba.az:8080/api/payment/?order_id=${localStorage.getItem('order_id')}&session_id=${localStorage.getItem('session_id')}`)
                //Записать order_id и session_id на local storage
            }catch(error){
                console.log(error)
            }
            console.log(1)
        }else{
            console.log(0)
        }
    }, [])
    return(
        <HelmetProvider>
            <div className="pricechecker-main">
                <Helmet>
                <title>Price Checker</title>
                <meta name="description" content="Мониторинг и управление ценами в ' : 'İnternet mağazada qiymətlərin monitorinqi və idarə edilməsi" />
                <meta name="keywords" content="price checker, product price, azsayt.az, azsayt" />
                <link rel="canonical" href="https://azsayt.az/pricechecker" />
                </Helmet>
                <ModalRegister open={openModal} setOpen={setOpenModal}/>
                <ModalLogin open={openModalLogin} setOpen={setOpenModalLogin}/>
                <Navbar openModal={setOpenModal} openModalLogin={setOpenModalLogin}/>
                <div className="app-container">
                    <HomeHeader openModal={setOpenModal}/>
                    <HomeFeatures/>
                    <HomeVideoDoc/>
                    <HomeBottomTag openModal={setOpenModal}/>
                </div>
                <Footer/>
            </div>
        </HelmetProvider>
    )
}